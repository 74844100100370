.r6dltkj{font-family:'Yandex Sans Text Web',-apple-system,'Segoe UI',Roboto,Helvetica,Arial,sans-serif;}
.ig21lpr{font-size:16px;font-weight:400;line-height:19px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;background-clip:padding-box;background-color:#f5f4f2;border:2px solid #f5f4f2;border-radius:16px;padding:0px 12px;-webkit-transition:background-color 0.2s ease-out,border-color 0.2s ease-out;transition:background-color 0.2s ease-out,border-color 0.2s ease-out;}.ig21lpr:focus-within{background:none;border-color:rgba(0,0,0,0.75);}
.i1f3sizr{background-color:rgba(252,82,48,0.1);border-color:rgba(252,82,48,0.1);}.i1f3sizr:focus-within{border-color:#fc5230;}
.i5avz96{font:inherit;color:currentColor;width:100%;display:block;background:none;box-sizing:content-box;border:0;margin:0;-webkit-letter-spacing:inherit;-moz-letter-spacing:inherit;-ms-letter-spacing:inherit;letter-spacing:inherit;text-overflow:ellipsis;padding:13px 2px 12px;}.i5avz96::-webkit-input-placeholder{color:#9e9b98;}.i5avz96::-moz-placeholder{color:#9e9b98;}.i5avz96:-ms-input-placeholder{color:#9e9b98;}.i5avz96::-ms-input-placeholder{color:#9e9b98;}.i5avz96:focus{outline:0;}[dir='rtl'] .i5avz96{text-align:right;}
.a1x0dzdl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.aghn7u6{margin-left:12px;}[dir='rtl'] .aghn7u6{margin-left:0;margin-right:12px;}
.a15ih21v{margin-right:12px;}[dir='rtl'] .a15ih21v{margin-right:0;margin-left:12px;}
.h1jhto9x{font-size:13px;font-weight:400;line-height:15px;margin-top:2px;color:#9e9b98;}
.tvfho06{color:#fc5230;}
.l1xsllmv{font:inherit;color:currentColor;width:100%;display:block;background:none;box-sizing:content-box;border:0;margin:0;-webkit-letter-spacing:inherit;-moz-letter-spacing:inherit;-ms-letter-spacing:inherit;letter-spacing:inherit;text-overflow:ellipsis;padding:22px 0px 7px;}.l1xsllmv::-webkit-input-placeholder{color:#9e9b98;}.l1xsllmv::-moz-placeholder{color:#9e9b98;}.l1xsllmv:-ms-input-placeholder{color:#9e9b98;}.l1xsllmv::-ms-input-placeholder{color:#9e9b98;}.l1xsllmv:focus{outline:0;}
.ign3mlg{font-size:16px;font-weight:400;line-height:19px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;position:relative;-webkit-transition:box-shadow 0.2s ease-out;transition:box-shadow 0.2s ease-out;box-shadow:0 -2px 0 -1px #d2d0cc inset;}.ign3mlg:focus-within{box-shadow:0 -3px 0 -1px #302f2d inset;}
.ii8h3cf{box-shadow:0 -2px 0 -1px #fc5230 inset;}.ii8h3cf:focus-within{box-shadow:0 -3px 0 -1px #fc5230 inset;}
.l1o0xgk2{font-size:16px;font-weight:400;line-height:19px;pointer-events:none;color:#9e9b98;position:absolute;top:16px;left:0;-webkit-transition:all 0.2s ease-out;transition:all 0.2s ease-out;}.l1xsllmv:focus ~ .l1o0xgk2,.l1xsllmv:valid ~ .l1o0xgk2{font-size:13px;font-weight:400;line-height:14px;top:8px;}[dir='rtl'] .l1o0xgk2{left:auto;right:0;}
.tb0c84j{color:#fc5230;}
