.rksaa3w{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;background-color:#f2f1ee;border-radius:8px !important;padding:2px;overflow:hidden;}@media (max-width:1024px){.rksaa3w{border-radius:10px !important;}}
.bsolr3r{border-radius:8px !important;height:-webkit-fit-content !important;height:-moz-fit-content !important;height:fit-content !important;text-align:start;padding:16px 8px 10px 8px !important;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:start;-webkit-justify-content:start;-ms-flex-pack:start;justify-content:start;width:100%;color:#5a5957;cursor:pointer;font-size:13px;-webkit-letter-spacing:normal;-moz-letter-spacing:normal;-ms-letter-spacing:normal;letter-spacing:normal;}@media (max-width:1024px){.bsolr3r{padding-top:10px !important;}}
.tlwk9ou{line-height:1.2;margin-bottom:3px;font-weight:500;padding:0 8px;}
.tpht2oa{opacity:0.3;}
.tzmkp9y{color:#000;background:#fff;}
.t1wb2s48{background-image:url('./images/cargo.svg');height:20px;width:49px;background-repeat:no-repeat;background-position:bottom left;margin:8px 0 0 8px;padding:0;-webkit-flex-basis:20px;-ms-flex-preferred-size:20px;flex-basis:20px;}@media (max-width:1024px){.t1wb2s48{width:41px;height:15px;background-size:auto 15px;}}
.t1y4guhf{background-image:url('./images/express.svg');height:20px;width:49px;background-repeat:no-repeat;background-position:bottom left;margin:8px 0 0 8px;padding:0;-webkit-flex-basis:20px;-ms-flex-preferred-size:20px;flex-basis:20px;}@media (max-width:1024px){.t1y4guhf{width:41px;height:15px;background-size:auto 15px;}}
.t1hdf8zs{background-image:url('./images/courier.svg');height:20px;width:49px;background-size:45px 20px;background-repeat:no-repeat;background-position:bottom left;margin:8px 0 0 8px;padding:0;-webkit-flex-basis:20px;-ms-flex-preferred-size:20px;flex-basis:20px;}@media (max-width:1024px){.t1hdf8zs{width:41px;height:15px;background-size:auto 15px;}}
.t1fw6ag8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:start;-webkit-box-align:start;-ms-flex-align:start;align-items:start;}
.p8lucyf{line-height:1;color:#000;white-space:nowrap;padding:0 8px 8px;}@media (max-width:1024px){.p8lucyf{font-size:11px;}}
.l16aoefu{position:relative;overflow:hidden;}@-webkit-keyframes loading-l16aoefu{0%{-webkit-transform:translateX(-100%);-ms-transform:translateX(-100%);transform:translateX(-100%);}100%{-webkit-transform:translateX(300%);-ms-transform:translateX(300%);transform:translateX(300%);}}@keyframes loading-l16aoefu{0%{-webkit-transform:translateX(-100%);-ms-transform:translateX(-100%);transform:translateX(-100%);}100%{-webkit-transform:translateX(300%);-ms-transform:translateX(300%);transform:translateX(300%);}}.l16aoefu:before{content:'';pointer-events:none;position:absolute;left:0;top:0;bottom:0;width:33.3333%;-webkit-animation:loading-l16aoefu 1s infinite linear;animation:loading-l16aoefu 1s infinite linear;-webkit-transform:translateX(-100%);-ms-transform:translateX(-100%);transform:translateX(-100%);}
.h1cw36nq{color:#fc5230;font-size:13px;font-weight:400;line-height:15px;margin-top:-15px;}
